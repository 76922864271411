<template>
  <div>
    <v-card>
      <v-card-title>Paras y Varadas</v-card-title>
      <v-card-text>
        <botonera 
          :botones="botones" 
          alinear="right"
          @crear="dialog = !dialog"
        ></botonera>
      </v-card-text>
    </v-card>
    <div class="card mt-2">
      <div class="card-header card-header-icon card-header-rose">
        <div class="card-icon blue darken-2">
            <i class="material-icons">list</i>
        </div>
        <h3 class="card-title">Listado de paradas y varadas</h3>
      </div>
      <div class="card-body">
        <v-data-table 
          :headers="parasListHeaders" 
          :items="parasListBody" 
          fixed-header
          :height="table_height"
          :header-props="{ sortIcon: 'arrow_upward' }"
        >
          <template v-slot:item.inicio="{ item }">
            <span>{{ moment(item.inicio).format("DD-MM-YYYY") }}</span>
          </template>
          <template v-slot:item.termino="{ item }">
            <span>{{ moment(item.termino).format("DD-MM-YYYY") }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  medium
                  class="mr-2"
                  @click="editPara(item)"
                  v-on="on"
                  color="orange"
                >
                  edit
                </v-icon>
              </template>
              <span>Editar para</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  medium
                  @click="deletePara(item)"
                  v-on="on"
                  color="red"
                >
                delete
                </v-icon>
              </template>
              <span>Eliminar para</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- Modal Nuevo Para -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col cols="4">
                <v-select
                  prepend-icon="mdi-factory"
                  v-model="para.tipo_para"
                  :items="tipos_paras"
                  item-text="nombre"
                  item-value="id"
                  label="Tipos de Para"
                  color="blue darken-3"
                  persistent-hint
                  return-object
                >
                </v-select>
            </v-col>
            <v-col>
                <v-select
                  prepend-icon="mdi-factory"
                  v-model="para.wellboat"
                  :items="wellboats"
                  item-text="nombre"
                  item-value="id"
                  label="Wellboats"
                  color="blue darken-3"
                  persistent-hint
                  return-object
                >
                </v-select>
            </v-col>
            <v-col>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="inicio_formated"
                      label="Fecha de Inicio"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model = "para.inicio"
                    @input = "menu1 = false"
                    locale = "es-cl"
                    prev-icon="chevron_left"
                    next-icon="chevron_right"
                  ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col>
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="termino_formated"
                        label="Fecha de Inicio"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model = "para.termino"
                        @input = "menu2 = false"
                        locale = "es-cl"
                        prev-icon="chevron_left"
                        next-icon="chevron_right"
                    ></v-date-picker>
                </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="closeModal"
          >Cerrar</v-btn>
          <v-btn
            color="primary"
            @click="savePara"
          >Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  .card{
    margin-top: 0;
  }
  .card .card-header-rose .card-icon{
    background: #263238;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(32, 30, 31, 0.822);
  }
</style>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      title: 'Nueva Para o Varada',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      success: null,
      search: null,
      dialog: false,
      paraIndex: -1,
      wellboats: [],
      tipos_paras: [],
      menu1: false,
      menu2: false,
      table_height: 500,
      para: {
        id: 0,
        wellboat: {},
        tipo_para: {},
        inicio: '',
        termino: '',
      },
      canEdit: true,
      canDelete: true,
      parasListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Tipo',
          align: 'center',
          sortable: true,
          value: 'tipo_para.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Wellboat',
          align: 'center',
          sortable: true,
          value: 'wellboat.nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Inicio',
          align: 'center',
          sortable: true,
          value: 'inicio',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Termino',
          align: 'center',
          sortable: true,
          value: 'termino',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      parasListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nueva Factura'},
        {boton:'cargar',tooltip:'Carga Masiva'},
        //{boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadWellboats();
      this.loadTipos();
      this.loadParas();
      if(window.innerHeight < 768){
        this.table_height = 300;
      }
    },
    methods:{
        async loadTipos(){
            let url = `${this.base_url}tipos_paras`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.tipos_paras = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadWellboats(){
            let url = `${this.base_url}wellboats/lista/select`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.wellboats = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadParas(){
            this.mostrarLoading('Cargando listado de paras...')
            let url = `${this.base_url}paras_wellboats`;
            await this.axios.get(url, this.headers).then((response)=>{
            this.parasListBody = response.data;
            this.ocultarLoading()
            }).catch((error)=>{
                console.log(error);
                this.ocultarLoading()
            });
        },
        async savePara(){
            // validation
            this.errors = [];
            if(!this.para.wellboat){
                this.errors.push("Debe indicar Wellboat");
            }
            if(!this.para.tipo_para){
                this.errors.push("Debe indicar tipo de varada");
            }
            if(!this.para.inicio){
                this.errors.push("Debe indicar fecha de inicio de la para");
            }
            if(!this.para.termino){
                this.errors.push("Debe indicar fecha de termino de la para");
            }
            if(this.errors.length > 0){
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
                return;
            }
            let url = `${this.base_url}paras_wellboats`;
            let paraData = {
                'wellboats_id': this.para.wellboat.id,
                'tipos_paras_id': this.para.tipo_para.id,
                'inicio': this.para.inicio,
                'termino': this.para.termino,
            };
            if(this.para.id > 0){
            url = url + "/" + this.para.id;
            await this.axios.put(url, paraData, this.headers).then((response)=>{
                let index = this.parasListBody.map(para => {
                    return para.id;
                }).indexOf(this.para.id);
                this.parasListBody.splice(index, 1, response.data);
                this.showSnackBar(false);
            }).catch((error)=>{
                this.validation_message = error;
                this.showSnackBar(true);
            });
            }else{
            await this.axios.post(url, paraData, this.headers).then((response)=>{
                this.parasListBody.push(response.data);
                this.showSnackBar(false);
            }).catch((error)=>{
                this.errors = error.response.data;
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
            });
            }
            this.cleanForm();
        },
        editPara(item) {
            this.paraIndex = this.parasListBody.indexOf(item);
            this.para = Object.assign({}, item);
            this.dialog = true;
            this.title = "Editar Para";
        },
        async deletePara(item) {
          this.$confirm('Desea borrar esta para?').then(res => {
            if(res){
              let url = `${this.base_url}paras/${item.id}`;
              this.axios.delete(url, this.headers).then((response)=>{
                console.log(response);
                const index = this.parasListBody.indexOf(item);
                this.parasListBody.splice(index, 1);
                this.showSnackBar(false);
              }).catch((error)=>{
                this.validation_message = error;
                this.showSnackBar(true);
              });
            }
          });
        },
        showSnackBar(error){
            if(error){
                this.color = 'red';
            }else{
                this.color = 'green';
                this.validation_message = "Ejecutado exitosamente!";
            }
            this.snackbar = true;
        },
        closeModal(){
            this.cleanForm();
            this.dialog = false;
            this.error = null;
            this.title = "Nueva Para o Varada";
        },
        cleanForm(){
            this.para = {
                id: 0,
                nombre: '',
            };
            this.dialog = false;
        },
        async ocultar(item) {
            this.$confirm('Desea ocultar esta para?').then(res => {
                if(res){
                    let url = `${this.base_url}paras/ocultar/${item.id}`;
                    this.axios.get(url, this.headers).then((response)=>{
                        console.log(response);
                        this.showSnackBar(false);
                        this.loadParas();
                    }).catch((error)=>{
                        this.validation_message = error;
                        this.showSnackBar(true);
                    });
                }
            });
        },
        async mostrar(item) {
            this.$confirm('Desea mostrar esta para?').then(res => {
            if(res){
                let url = `${this.base_url}paras/mostrar/${item.id}`;
                this.axios.get(url, this.headers).then((response)=>{
                    console.log(response);
                    this.showSnackBar(false);
                    this.loadParas();
                }).catch((error)=>{
                    this.validation_message = error;
                    this.showSnackBar(true);
                });
            }
        });
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      inicio_formated () {
        return this.formatDate(this.para.inicio)
      },
      termino_formated () {
        return this.formatDate(this.para.termino)
      },
      
    },
    components:{
      
    }
  }
</script>