var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Paras y Varadas")]),_c('v-card-text',[_c('botonera',{attrs:{"botones":_vm.botones,"alinear":"right"},on:{"crear":function($event){_vm.dialog = !_vm.dialog}}})],1)],1),_c('div',{staticClass:"card mt-2"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('v-data-table',{attrs:{"headers":_vm.parasListHeaders,"items":_vm.parasListBody,"fixed-header":"","height":_vm.table_height,"header-props":{ sortIcon: 'arrow_upward' }},scopedSlots:_vm._u([{key:"item.inicio",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.inicio).format("DD-MM-YYYY")))])]}},{key:"item.termino",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.termino).format("DD-MM-YYYY")))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"medium":"","color":"orange"},on:{"click":function($event){return _vm.editPara(item)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v("Editar para")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.deletePara(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar para")])])]}}])})],1)]),_c('v-dialog',{attrs:{"width":"800px"},on:{"click:outside":_vm.closeModal},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"blue darken-1"},[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-factory","items":_vm.tipos_paras,"item-text":"nombre","item-value":"id","label":"Tipos de Para","color":"blue darken-3","persistent-hint":"","return-object":""},model:{value:(_vm.para.tipo_para),callback:function ($$v) {_vm.$set(_vm.para, "tipo_para", $$v)},expression:"para.tipo_para"}})],1),_c('v-col',[_c('v-select',{attrs:{"prepend-icon":"mdi-factory","items":_vm.wellboats,"item-text":"nombre","item-value":"id","label":"Wellboats","color":"blue darken-3","persistent-hint":"","return-object":""},model:{value:(_vm.para.wellboat),callback:function ($$v) {_vm.$set(_vm.para, "wellboat", $$v)},expression:"para.wellboat"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.inicio_formated),callback:function ($$v) {_vm.inicio_formated=$$v},expression:"inicio_formated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.para.inicio),callback:function ($$v) {_vm.$set(_vm.para, "inicio", $$v)},expression:"para.inicio"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.termino_formated),callback:function ($$v) {_vm.termino_formated=$$v},expression:"termino_formated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.para.termino),callback:function ($$v) {_vm.$set(_vm.para, "termino", $$v)},expression:"para.termino"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeModal}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.savePara}},[_vm._v("Grabar")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.validation_message)+" ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header card-header-icon card-header-rose"},[_c('div',{staticClass:"card-icon blue darken-2"},[_c('i',{staticClass:"material-icons"},[_vm._v("list")])]),_c('h3',{staticClass:"card-title"},[_vm._v("Listado de paradas y varadas")])])}]

export { render, staticRenderFns }